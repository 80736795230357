export function init() {
  window.getMessageFromAPP = (message) => {
    console.log(message)
  }
};

export function registerBridge() {

};

export function callMessage(module, message) {
  window.webkit.messageHandlers.callbackHandler.postMessage({
    key: JSON.stringify({
      module,
      message
    })
  })
};