import React from 'react';
import { useState, useEffect } from 'react';
import { init, callMessage } from './bridge.ts';
import { List, ListItem, ListItemText, ListItemButton } from '@mui/material';

export default function App() {
  const list = useState([1, 2, 3]);
  useEffect(() => {
    init();
  }, []);
  return (
    <div>
      {list.map(item => {
        return (<div>{item}</div>)
      })}
    </div>
  )
}
